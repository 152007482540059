<template>
  <div
    :style="[
      `--section-primary-color: ${template?.params?.primaryColor}`,
    ]"
    class="py-20 container flex justify-between items-center"
  >
    <div
      v-if="isDeclineMessageSubmitted"
      class="flex flex-col justify-center items-center w-full h-full"
    >
      <Lottie
        :animation-data="checkLottie"
        :height="150"
        :width="150"
        :loop="false"
        class="lottie-check"
      />

      <h2 class="text-page-xl font-bold mt-1.5 mb-0.5 text-black">
        <!-- TODO: remove condition later -->
        {{ props.event?._id === '5c0170aa2b4faff5ea2a57b5' || props.event?._id === '976859f4b024f2dcc406723f' ? 'We are sorry that you cannot participate' : useT('xyz3') }}
      </h2>
      <div class="text-gray-700 text-page-base">
        {{ useT('xyz4') }}
      </div>
    </div>
    <div
      v-else
      class="w-full max-w-5xl px-3 flex items-center justify-between mx-auto flex-col md:flex-row"
    >
      <div class="w-full md:w-80 max-w-[36rem] md:max-w-full mb-8 md:mb-0">
        <template v-if="isSubmitted">
          <h1 class="text-page-2xl font-bold mb-4">
            {{ useT('xyz5') }}
          </h1>

          <TextArea
            v-model="declineMessage"
            :placeholder="useT('xyz6')"
            class="mb-6"
          />

          <Button
            type="primary"
            size="large"
            class="w-full"
            :loading="isSubmitting"
            @click="sendDeclineMessage()"
          >
            {{ useT('xyz452') }}
          </Button>
        </template>
        <template v-else>
          <h1 class="text-page-5xl font-bold mb-3">
            {{ useT('xyz7') }}
          </h1>
          <p class="text-page-base text-gray-700 mb-6">
            {{ useT('xyz8') }}
          </p>
          <Button
            type="danger"
            size="large"
            class="w-full"
            :loading="isSubmitting"
            @click="declineAttendance()"
          >
            {{ useT('xyz9') }}
          </Button>
        </template>
      </div>

      <div class="max-w-[36rem] w-full border border-gray-200 rounded-xl p-6">
        <div
          v-if="useWorkspaceStore().identity?.logo"
          class="pb-4 border-b border-gray-200"
        >
          <img
            :src="useWorkspaceStore().identity.logo"
            :alt="useWorkspaceStore().identity.name"
            class="w-[6.25rem] h-auto"
          >
        </div>
        <div class="py-4">
          <h2 class="text-page-xl mb-0.5">
            {{ template?.params?.general?.[`_${props.lang}`]?.eventTitle }}
          </h2>
          <div class="text-gray-700 text-page-base">
            {{ getFormattedDate(
              props.event?.general?.startDate,
              {
                lang: props.lang,
                format: `ll [${useT('xyz10')}] LT`,
                timezone: props.event?.general?.timezone,
                showTimezoneDiff: true,
              },
            ) }}
            <template v-if="props.event?.general?.location?.placeName">
              •
              {{ props.event.general.location.placeName }},
              {{ props.event.general.location.address }},
              {{ props.event.general.location.city }}
            </template>
          </div>
        </div>
        <div
          v-if="props.guest"
          class="pt-4 border-t border-gray-200 flex items-center gap-3 flex-wrap"
        >
          <Avatar size="md" />
          <div class="flex-1">
            <h3 class="text-page-lg leading-tight">
              {{ props.guest.properties?.firstName }} {{ guest.properties?.lastName }}
            </h3>
            <div class="text-page-base">
              {{ props.guest.properties?.email }}
            </div>
          </div>
          <div>
            <Badge
              v-if="props.guest?.status?.confirmation === 0"
              :text="useT('xyz11')"
              type="neutral"
            />

            <Badge
              v-if="props.guest?.status?.confirmation > 0"
              :text="useT('xyz12')"
              type="success"
            />

            <Badge
              v-if="props.guest?.status?.confirmation === -1"
              :text="useT('xyz13')"
              type="critical"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import checkLottie from '~/assets/json/lottie/check.json';

const props = defineProps({
  event: {
    type: Object,
    required: true,
  },
  guest: {
    type: Object,
    default: null,
  },
  template: {
    type: Object,
    required: true,
  },
  lang: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['refresh-guest']);

// Refs
const isSubmitting = ref(false);
const isSubmitted = ref(false);
const isDeclineMessageSubmitted = ref(false);
const declineMessage = ref('');

// Methods
const declineAttendance = async() => {
  isSubmitting.value = true;
  try {
    await useApi.post(`/public/guests/${props.guest._id}/decline`);
    emit('refresh-guest');
    isSubmitted.value = true;
  } catch (error) {
    console.error(error);
  }
  isSubmitting.value = false;
};

const sendDeclineMessage = async() => {
  if (declineMessage.value?.trim()?.length === 0) {
    isDeclineMessageSubmitted.value = true;
    return;
  }

  isSubmitting.value = true;

  try {
    await useApi.post(`/public/guests/${props.guest._id}/decline-message`, {
      body: {
        declineMessage: declineMessage.value,
      },
    });
    isDeclineMessageSubmitted.value = true;
  } catch (error) {
    console.error(error);
    useToast.error(useT('xyz106'));
  }

  isSubmitting.value = false;
};
</script>

<style>
.lottie-check {
  path[fill="rgb(40,166,69)"] {
    fill: var(--section-primary-color);
  }

  path[fill="rgb(239,255,243)"] {
    fill: var(--section-primary-color);
    fill-opacity: 0.15;
  }
}
</style>
